@import "styles/variables";
//color
:root {
  --v-main-bg: #e9e0d6;
  --v-main-color: #231816;
  --color-beige200: #e9e0d6;
  --color-beige300: #e2d7c9;
  --color-beige400: #ddcdbb;
  --color-lightbrown: #c5b6a6;
  --color-brown: #baa893;
  --color-anzu: #ebbf8f;
  --color-white: #ffffff;
  --font-ja: YakuHanJP, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic";
  --font-en: YakuHanJP, "baskerville-display-pt", "YuMincho", "Yu Mincho";
  --ease-scale: all 2.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0s;
  --ease: all 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0s;
  --ease-nav: transform .6s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0s;
  --ease-menuOpen: all .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0s;;
  --ease-menuClose: all .4s cubic-bezier(0.550, 0.055, 0.675, 0.190) 0s;
}

//font
@import url("https://use.typekit.net/ytn1mmt.css");
$font-ja: 游ゴシック, "Yu Gothic", 游ゴシック体, YuGothic;
$font-family: var(--font-ja), "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;


html {
  background: var(--v-main-bg);
  color: var(--v-main-color);
  line-height: 1.8;
  font-size: 62.5%;
}

body {
  width: 100%;
  font-family: $font-family;
  overflow-wrap: break-word;
  font-size: 1.4rem;
  font-weight: 400;
  font-style: normal;
  font-feature-settings: "palt";
  letter-spacing: .05em;
  text-align: left;
  tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .pc-only {
    display: block;
  }
  .sp-only {
    display: none;
  }
}

// Swiper js Style
.mainVisual {
  li {
    .is-active {
      width: 14px!important;
      height: 14px!important;
      background-color: transparent!important;
      border: 1px solid white;
    }
    img {
      transition: all 18s linear 1s;
      transform: scale(1.12);
      will-change: transform;
    }
    &.is-active {
      img {
        transition: all 18s linear;
        transform: scale(1);
        will-change: transform;
      }
    }
    @media screen and (max-width: 768px) {
      .is-active {
        display: none!important;
      }
    }
  }

}
.sectionProduct {
  .mySwiper {
    .swiper-slide-thumb-active {
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border: 2px solid var(--color-anzu);
      }
    }
  }
}

.sectionHowto {
  .swiper-thumbs {
    display: flex;
    flex-direction: column-reverse;
    .swiper-pagination {
      font-family: var(--font-en);
      font-size: 2.2rem;
      span {
        font-size: 2.8rem;
        display: inline-block;
        transform: translateY(3px);
        &.swiper-pagination-current {
          font-size: 5rem;
          font-weight: 100;
          transform: translateY(3px);
          display: inline-block;
          margin-left: 10px;
          @include mq(md) {
            font-size: 4rem;
          }
        }
        &::before {
          content: "0";
        }
      }
    }
    .swiper-slide {
      background: var(--v-main-bg);
      font-size: 2.0rem;
    }
  }
  .swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.postDetailPage {
  @media screen and (max-width: 768px) {
      .md-justifycenter {
        justify-content: center;
      }
  }
  .post-button-parts {
    border-radius: 50%;
    padding: 30px 0;
    font-family: var(--font-en);
    font-size: 1.6rem;
    display: inline-block;
    transition: all .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 320px;
    text-align: center;
    z-index: 0;
    user-select: none;
    background-color: var(--v-main-color);
    color: var(--v-main-bg)!important;
    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      bottom: -0%;
      left: -0%;
      z-index: -1;
      width: 100%;
      height: 100%;
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(1, 0);
      transform-origin: left top;
      background-color: var(--color-anzu);
      color: var(--v-main-color);
    }
    @media screen and (min-width: 768px) {
      &:hover {
        transition: color .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        &::after {
          transform-origin:left bottom;
          transform:scale(1, 1);
        }
        transition: background-color 0s linear .6s, color .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        background-color: var(--color-anzu);
        color: var(--v-main-color)!important;
      }
    }
  }
}

.is-true-scaleIn {
  transform: scale(1.0)!important;
}